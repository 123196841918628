import React, { useState } from "react";
import axios from "axios";
import useAdminCheck from "../rechte_admin";

const RegisterForm = () => {
  useAdminCheck();
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(""); // Standardwert leer lassen
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://db.xocore.de/neu/register", {
        vorname,
        nachname,
        password,
        role,
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Fehler beim Registrieren des Benutzers");
      console.error("Fehler beim Registrieren des Benutzers:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Registrieren</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="vorname" className="form-label">Vorname:</label>
          <input
            type="text"
            id="vorname"
            className="form-control"
            value={vorname}
            onChange={(e) => setVorname(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="nachname" className="form-label">Nachname:</label>
          <input
            type="text"
            id="nachname"
            className="form-control"
            value={nachname}
            onChange={(e) => setNachname(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Passwort:</label>
          <input
            type="password"
            id="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="role" className="form-label">Rolle:</label>
          <select
            id="role"
            className="form-control"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="">Bitte wählen...</option>
            <option value="10">Trainer</option>
            <option value="20">Mitarbeiter</option>
            <option value="21">SuperUser</option>
          </select>
        </div>
        <button type="submit" className="btn btn-danger">Registrieren</button>
      </form>
      {message && <p className="mt-3 text-center">{message}</p>}
    </div>
  );
};

export default RegisterForm;
