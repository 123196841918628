import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const SuccessPageTrainer = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 text-center">
      <FontAwesomeIcon icon={faCheckCircle} className="text-success" size="6x" />
      <h2 className="mt-3">Trainer wurde erfolgreich angelegt</h2>
      <Link to="/" className="btn btn-success mt-4">
      ----- Weiter -----
      </Link>
    </div>
  );
};

export default SuccessPageTrainer;
