import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import CustomAlert from "../customAlert";
import useAdminCheck from "../rechte_admin";
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function TrainerProfilErstellen() {

  
  useAdminCheck();
  const { trainerid } = useParams();
  console.log("Trainer ID:", trainerid);

  const [buttonColor, setButtonColor] = useState("danger");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate(); // useNavigate hinzugefügt
  


  const [checkedItems, setCheckedItems] = useState({});
  const items = ["Anlage E-Mail", "Teams Benutzer", "Viva Vorstellung", "Versand Welcome Pack", "Onboardinggespräch", "Rahmenvertrag", "Campus Trainerkurs", "Hospitation abgeschlossen"];

  const [editableData, setEditableData] = useState({
    Wohnort: "",
    trainer_plz: "",
    trainer_strasse: "",
    trainer_geburtsdatum: "",
    trainer_telefonnummer: "",
    trainer_trainingstage: "",
    Steuernummer: "",
    Umsatzsteuernummer: "",
    Umsatzsteuerregel: "0",
  });

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertType("");
    setAlertMessage("");
  };

  const convertISODateToInputFormat = (isoDate) => {
    if (!isoDate) return ""; // Sicherstellen, dass ein Datum vorhanden ist
    const datePortion = isoDate.split("T")[0];
    return datePortion;
  };

  // Event-Handler für Änderungen an den Formularfeldern
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Event-Handler für den Speichern-Button
  const handleSpeichernClick = async () => {
    setShowAlert(false);
    setAlertType("");
    setAlertMessage("");

    try {
      // PUT Anfrage an den Server senden
      const response = await axios.put(
        `https://db.xocore.de/neu/register/${trainerid}`, // Endpunkt mit TrainerID
        {
          Wohnort: editableData.Wohnort,
          trainer_plz: editableData.trainer_plz,
          trainer_strasse: editableData.trainer_strasse,
          trainer_geburtsdatum: editableData.trainer_geburtsdatum,
          trainer_telefonnummer: editableData.trainer_telefonnummer,
          trainer_trainingstage: editableData.trainer_trainingstage,
          Steuernummer: editableData.Steuernummer,
          Umsatzsteuernummer: editableData.Umsatzsteuernummer,
          Umsatzsteuerregel: editableData.Umsatzsteuerregel,
        }
      );
      if (response.status === 200) {
        setAlertType("success");
        setAlertMessage("Daten erfolgreich gespeichert!");
        setShowAlert(true);

      }
    } catch (error) {
      setAlertType("danger");
      setAlertMessage("Fehler beim Speichern der Daten. Bitte versuchen Sie es erneut.");
      setShowAlert(true);
    }
  };




  return (
    <Fragment>
      <CustomAlert
        message={alertMessage}
        type={alertType}
        onClose={handleAlertClose}
      />

      <div className="container mt-3">
        
        <div className="row">

          <div className="col-md-6">
            <h3>{trainerid} // Trainerregistierung</h3>
          </div>
          <div className="col-md-6">
            
            <div className="col-md-12">
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Muster Checkliste </Accordion.Header>
                  <Accordion.Body>
                  <div className="container mt-4">
      <div className="list-group">
        {items.map((item, index) => (
          <label key={index} className="list-group-item d-flex align-items-center">
            <input
              type="checkbox"
              name={item}
              checked={checkedItems[item] || false}
              className="form-check-input me-2"
            />
            {item}
          </label>
        ))}
      </div>
    </div>

                </Accordion.Body>
                </Accordion.Item>
                


              </Accordion>            
                </div>


          </div>

        </div>
        <div className="row">
          <div className="col-md-6">
            <form className="row g-3">
              <div className="col-md-12">
                <label htmlFor="trainer_geburtsdatum" className="form-label">
                  Geburtsdatum
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="trainer_geburtsdatum"
                  value={convertISODateToInputFormat(editableData.trainer_geburtsdatum)}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="trainer_strasse" className="form-label">
                  Straße Hausnummer
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  name="trainer_strasse"
                  className="form-control"
                  value={editableData.trainer_strasse}
                  required
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="trainer_plz" className="form-label">
                  PLZ
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  className="form-control"
                  name="trainer_plz"
                  value={editableData.trainer_plz}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="Wohnort" className="form-label">
                  Ort
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  className="form-control"
                  name="Wohnort"
                  value={editableData.Wohnort}
                  required
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="trainer_telefonnummer" className="form-label">
                  Telefon
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  className="form-control"
                  name="trainer_telefonnummer"
                  value={editableData.trainer_telefonnummer}
                  required
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="trainer_mail" className="form-label">
                  Mailadresse
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  className="form-control"
                  name="trainer_mail"
                  value={editableData.trainer_mail}
                  required
                />
              </div>
              
              <div className="col-md-12">
                <label htmlFor="trainer_trainingstage" className="form-label">
                Jahreskapazität in Tagen
                </label>
                <input
                  type="number"
                  onChange={handleInputChange}
                  className="form-control"
                  name="trainer_trainingstage"
                  value={editableData.trainer_trainingstage}
                  required
                />
              </div>

              <div className="col-md-12">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Umsatzsteuer berechnen (Kein Kleinunternehmer)
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <label htmlFor="Steuernummer" className="form-label">
                  Steuernummer
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  className="form-control"
                  name="Steuernummer"
                  value={editableData.Steuernummer}
                  required
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="Umsatzsteuernummer" className="form-label">
                  Umsatzsteuernummer
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  className="form-control"
                  name="Umsatzsteuernummer"
                  value={editableData.Umsatzsteuernummer}
                  
                />
              </div>

              <div className="col-md-12">
                <button
                  type="button"
                  className={`btn btn-${buttonColor} mb-2 w-100`}
                  onClick={handleSpeichernClick}
                >
                  Beauftragung Trainer 
                </button>
              </div>
            </form>
            
          </div>

          
        </div>
        
      </div>
    </Fragment>
  );
}

export default TrainerProfilErstellen;
