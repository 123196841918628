import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CustomAlert from "../customAlert";
import useAdminCheck from "../rechte_admin";

function Onboardingcheckliste() {
  useAdminCheck();
  const { trainerid } = useParams();
  const navigate = useNavigate();

  const [checkedItems, setCheckedItems] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const checklistFields = {
    trainer_checkliste_mailanlage: "Anlage E-Mail",
    trainer_checkliste_teams: "Teams Benutzer",
    trainer_checkliste_vivaengage: "Viva Vorstellung",
    trainer_checkliste_welcomepaket: "Versand Welcome Pack",
    trainer_checkliste_onboardinggespraech: "Onboardinggespräch",
    trainer_checkliste_vertrag: "Rahmenvertrag",
    trainer_checkliste_campus_trainerkurs: "Campus Trainerkurs",
    trainer_checkliste_hospitation: "Hospitation abgeschlossen"
  };

  useEffect(() => {
    const fetchChecklist = async () => {
      try {
        const response = await axios.get(`https://db.xocore.de/trainer/checkliste/${trainerid}`);
        setCheckedItems(response.data);
      } catch (error) {
        console.error("Fehler beim Laden der Checkliste:", error);
      }
    };
    fetchChecklist();
  }, [trainerid]);

  const handleCheckboxChange = async (field) => {
    const updatedItems = { ...checkedItems, [field]: !checkedItems[field] };
    setCheckedItems(updatedItems);

    try {
      await axios.put(`https://db.xocore.de/trainer/checkliste/${trainerid}`, {
        trainer_checkliste_trainer_id: trainerid,
        ...updatedItems
      });
      setAlertType("success");
      setAlertMessage("Daten erfolgreich gespeichert!");
      setShowAlert(true);
    } catch (error) {
      setAlertType("danger");
      setAlertMessage("Fehler beim Speichern der Daten.");
      setShowAlert(true);
    }
  };

  return (
    <Fragment>
      <CustomAlert message={alertMessage} type={alertType} onClose={() => setShowAlert(false)} />
      <div className="container mt-3">
        <h3>{trainerid} // Trainerregistrierung</h3>
        <div className="list-group">
          {Object.entries(checklistFields).map(([field, label]) => (
            <label key={field} className="list-group-item d-flex align-items-center">
              <input
                type="checkbox"
                checked={checkedItems[field] || false}
                className="form-check-input me-2"
                onChange={() => handleCheckboxChange(field)}
              />
              {label}
            </label>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Onboardingcheckliste;
