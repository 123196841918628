import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faFileInvoice, faFlag, faHourglassStart, faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import Rating from 'react-rating-stars-component';

class TrainerQualificationElement extends Component {
    state = {
        rating: this.props.rating || 3.5, // Initialwert aus Props
        isEditing: true // Zustand zur Steuerung der Bearbeitungsansicht
    };

    toggleEdit = () => {
        this.setState({ isEditing: !this.state.isEditing });
    };

    handleRatingChange = async (newRating) => {
        this.setState({ rating: newRating });
        
        try {
            const response = await fetch(`https://db.xocore.de/db/trainer/concept/${this.props.concept_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.props.konzept,
                    rating: newRating,
                    rank: this.props.qualistufe,
                    trainer_id: this.props.trainer_id
                })
            });
            
            if (!response.ok) {
                throw new Error('Fehler beim Aktualisieren der Bewertung');
            }
            
            console.log('Bewertung erfolgreich aktualisiert');
        } catch (error) {
            console.error('Update fehlgeschlagen:', error);
        }
    };

    render() {
        const iconMappings = {
            1: faSquareCheck,
            2: faCoffee,
            3: faHourglassStart,
            4: faFlag
        };

        const selectedIcon = iconMappings[this.props.icon] || faSquare;
        
        return (
            <div className="list-group-item list-group-item-action" >
                <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className="col-md-2"><h5 className="mb-1">{this.props.konzept}</h5></div>
                    <div>
                        {this.props.qualistufe}
                        {this.state.isEditing ? (
                            <Rating 
                                count={5}
                                value={this.state.rating}
                                size={24}
                                onChange={this.handleRatingChange}
                                activeColor="#ffd700"
                            />
                        ) : (
                            <Rating 
                                count={5}
                                value={this.state.rating}
                                size={24}
                                edit={false}
                                activeColor="#ffd700"
                            />
                        )}
                    </div>
                    <FontAwesomeIcon icon={selectedIcon} style={{ color: 'white' }} onClick={this.toggleEdit} />
                </div>
            </div>
        );
    }
}

export default TrainerQualificationElement;